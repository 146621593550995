import axios from "axios";

const api = axios.create({

baseURL: "https://acqua-api.salesbreath.com.br/api/v1",

//  baseURL: "http://localhost:3008/api/v1/",
  headers: { "Content-Type": "application/json" }
});

export default api;
